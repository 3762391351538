import { BASE_URL } from "../../helper";
import api from "../../api";

// import Interceptor from "../interceptor";
// const request = new Interceptor();

export const JobDashboard = async (data) => {
  try {
    const response = await api.get(`${BASE_URL}/jobs/dashboard/${data}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const AppliedJobs = async (data) => {
  try {
    const response = await api.get(`${BASE_URL}/jobs/applied/${data}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const SupportUsList = async (data) => {
  try {
    const response = await api.get(`${BASE_URL}/settings/support/${data}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
