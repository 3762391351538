import { BASE_URL } from "../helper";
import api from "../api";

export const dashboard = async (data) => {
  try {
    const response = await api.get(`${BASE_URL}/dashboard/${data.id}`);
    return response.data; // Return the response data instead of calling success function
  } catch (error) {
    // You can handle errors here or let the component handle them
    throw error; // Throw the error to be caught in the component
  }
};

export const selectedResumeTemplate = async (data) => {
  try {
    const response = await api.post(
      `${BASE_URL}/dashboard/templates`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
