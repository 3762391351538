import { BASE_URL } from "../helper";
import api from "../api";
// import Interceptor from "./interceptor";
// const request = new Interceptor();

export const getProfile = async (data) => {
  try {
    const response = await api.get(`${BASE_URL}/profileupdate/${data.id}`);
    return response.data;  
  } catch (error) {
    throw error;  
  }
};

export const getReferrals = async (data) => {
  try {
    const id = data;
    const response = await api.get(`${BASE_URL}/profileupdate/referral/${id}`);
    return response.data; 
  } catch (error) {
    throw error;
  }
}

export const getMerchantReferrals = async (data) => {
  try {
    const id = data;
    const response = await api.get(`${BASE_URL}/merchant/referral/${id}`);
    return response.data; 
  } catch (error) {
    throw error;
  }
}