import React from "react";
import { Row, Col, Card, QRCode, Space } from "antd";
import { QrcodeOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import { ReferralsStyle } from "./styles";
import { useSelector } from "react-redux";

const ShareLinkComponent = () => {
  const currentUser = useSelector((state) => state.auth?.user?.userInfo);

  return (
    <ReferralsStyle>
      <Row gutter={[16, 16]} style={{ padding: "20px" }}>
        <Col xs={24} sm={24} md={8} className="card1">
          <Card title=" Invite Talent" bordered={false}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a
                href={`https://gtalentpro.com/admin/pdf/qrcode?uid=${currentUser?.uid}&id=${currentUser?.user_id}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textAlign: "center", cursor: "pointer" }}
              >
                <div>
                  <QRCode
                    type="svg"
                    bgColor="#fff"
                    style={{
                      marginBottom: 16,
                      display: "none",
                    }}
                  />

                  <p className="mb-1">
                    <QrcodeOutlined />
                  </p>
                  <p>
                    Download QR <CloudDownloadOutlined />{" "}
                  </p>
                </div>
              </a>
            </div>
          </Card>
        </Col>
      </Row>
    </ReferralsStyle>
  );
};

export default ShareLinkComponent;
