import React, { Fragment } from "react";
import { Breadcrumb } from "antd";
import Referrals from "../../components/Merchant/MerchantReferrals";

const MerchantReferralsMain = () => {
  return (
    <Fragment>
      <Breadcrumb style={{ margin: "16px 0", padding: "10px" }}>
        <Breadcrumb.Item>Referrals</Breadcrumb.Item>
      </Breadcrumb>
      <Referrals />
    </Fragment>
  );
};
export default MerchantReferralsMain;
