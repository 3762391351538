import React, { useState, useEffect } from "react";
import { Table, Tag, Spin } from "antd";
import moment from "moment";
import { profile } from "../../../utils/services/index";
import ShareLinkComponent from "./ShareLinks";
import { useDispatch, useSelector } from "react-redux";

const { Column } = Table;

const MerchantReferrals = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.auth?.user?.userInfo);

  const [data, setData] = useState([]);
  const [getuserId, setGetuserId] = useState(localStorage.getItem("grantUser"));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setGetuserId(localStorage.getItem("grantUser"));
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await profile.getMerchantReferrals(getuserId);
      setData(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <Spin spinning={loading}>
        <ShareLinkComponent />
        <div style={{ overflowX: "auto" }}>
          <Table dataSource={data}>
            <Column
              title="S.No"
              dataIndex="id"
              key="id"
              render={(text, record, index) => index + 1}
              responsive={["xs", "sm", "md", "lg"]}
            />
            <Column
              title="Name"
              dataIndex="name"
              key="name"
              responsive={["xs", "sm", "md", "lg"]}
            />

            <Column
              title="Status"
              dataIndex="verified"
              key="verified"
              render={(verified) => (
                <div>
                  {verified === 1 ? (
                    <Tag color="green">Verified</Tag>
                  ) : (
                    <Tag color="red">Un Verified</Tag>
                  )}
                </div>
              )}
              responsive={["xs", "sm", "md", "lg"]}
            />
            <Column
              title="Date"
              dataIndex="cu_date"
              key="cu_date"
              render={(cu_date) =>
                moment(cu_date).format("DD-MMM-YYYY").toLowerCase()
              }
              responsive={["xs", "sm", "md", "lg"]}
            />
          </Table>
        </div>
      </Spin>
    </>
  );
};
export default MerchantReferrals;
