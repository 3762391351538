import styled from "styled-components";

export const ReferralsStyle = styled.div`
.card1 .ant-card-head {
    text-align: center;
    background: rgb(59, 89, 152);
    color: rgb(255, 255, 255);
}

.card2 .ant-card-head {
    text-align: center;
    background: #39a883;
    color: rgb(255, 255, 255);
}

.card3 .ant-card-head {
    text-align: center;
    background: #007bb6;
    color: rgb(255, 255, 255);
}

`