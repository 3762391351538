import { dashboard, selectedResumeTemplate } from "./home";
import { resumeTemplates } from "./resume_templates";
import { getProfile, getReferrals, getMerchantReferrals } from "./profile";
import { JobDashboard, AppliedJobs, SupportUsList } from "./jobs/index";
export const templateService = {
  resumeTemplates,
};

export const homeService = {
  dashboard,
  selectedResumeTemplate,
};

export const profile = {
  getProfile,
  getReferrals,
  getMerchantReferrals
};

export const jobs = {
  JobDashboard,
  AppliedJobs,
  SupportUsList
};
