import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../utils/helper";
import Interceptor from "../../utils/services/interceptor";
import api from "../../utils/api";
import { resetDashboardState } from "../reducers/dashboard.reducer";
import { resetPricingState } from "../reducers/payment.reducer";
import { resetState } from "../reducers/auth.reducer";
const request = new Interceptor();

export const signInWithEmailPassword = createAsyncThunk(
  "auth/signInWithEmailAndPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post(`${BASE_URL}/auth/login`, {
        email: data.email,
        password: data.password,
      });
      return response.data;
    } catch (error) {
      console.error("Email login error:", error);
      throw rejectWithValue(error);
    }
  }
);

export const signOut = createAsyncThunk(
  "auth/signOut",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(`${BASE_URL}/auth/signout`);
      dispatch(resetDashboardState());
      dispatch(resetPricingState());
      dispatch(resetState());
      return response.data;
    } catch (error) {
      console.error("Sign out error:", error);
      return rejectWithValue(error.response.data);
    }
  }
);


export const MerchantSignInWithEmailPassword = createAsyncThunk(
  "auth/MerchantSignInWithEmailAndPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post(`${BASE_URL}/auth/partner-signin`, {
        email: data.email,
        password: data.password,
      });
      return response.data;
    } catch (error) {
      console.error("Email login error:", error);
      throw rejectWithValue(error);
    }
  }
);