import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { FaBars } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import {
  UserOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  HomeOutlined,
  FileTextOutlined,
  LoginOutlined,
  UserAddOutlined,
  CheckCircleOutlined,
  FileSearchOutlined,
  AuditOutlined,
  CarryOutOutlined,
  DiffOutlined,
  DollarOutlined
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../../../redux/actions/auth.actions";

import logo from "../../../assets/logo/logo.svg";
import "./navbar.css";

function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState("");
  const [grandUserID, setgrandUserId] = useState("");
  const [userIcon, setUserIcon] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const currentUser = useSelector((state) => state.auth?.user?.userInfo);
  const userRole = localStorage.getItem("role");

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const userName = localStorage.getItem("userName");
    const grandUser = localStorage.getItem("grantUser");

    if (userName) {
      const digits = userName.slice(0, 2);
      setUserIcon(digits);
    }
    setUserData(userName);
    setgrandUserId(grandUser);
    setLoading(false);
  };

  const handleLogout = async () => {
    const logg = localStorage.getItem("role");
    localStorage.removeItem("userId");
    localStorage.removeItem("grantUser");
    localStorage.removeItem("userName");
    localStorage.removeItem("role");
    localStorage.removeItem("uuid");
    localStorage.removeItem("isPaidCustomer");
    localStorage.removeItem("resumeTemplate");
    localStorage.removeItem("resumeTemplateGroup");
    localStorage.removeItem("token");
    localStorage.removeItem("talentId");
    localStorage.clear();
    await dispatch(signOut());
    if(logg == "U"){
      navigate("/signin");
    }else {
      navigate("/partner-signin");
    }
    
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const menuClickHandler = (e) => {
    switch (e.key) {
      case "profile":
        navigate("/profile");
        break;
      case "settings":
        navigate("/settings");
        break;
      case "logout":
        handleLogout();
        break;
      default:
        break;
    }
  };

  const handleMenuItemClick = () => {
    setMenuOpen(false); // Close the menu
  };

  const userMenu = (
    <Menu onClick={menuClickHandler}>
      {userRole === "U" && (
        <Menu.Item key="profile" icon={<UserOutlined />}>
          Profile Update
        </Menu.Item>
      )}
      <Menu.Item key="settings" icon={<SettingOutlined />}>
        Settings
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <nav className="header-navbar">
      <Link to={currentUser?.user_id ? "/dashboard" : "/"}>
        <img src={logo} alt="logo" />
      </Link>

      {loading ? (
        <></>
      ) : (
        <div className="navbar">
          <ul id="navbar">
            {userData ? (
              <>
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>

                <li>
                  <NavLink to="/dashboard" onClick={handleMenuItemClick}>Dashboard</NavLink>
                </li>
                {userRole !== "MP" && (
                  <>
                    <li>
                      <a
                        href={`https://gtalentpro.com/signin?token=${grandUserID}`}
                        target="_blank"
                      >
                        Jobs
                      </a>
                    </li>

                    <li>
                      <a
                        href={`https://gtalentpro.com/companies`}
                        target="_blank"
                      >
                        Companies
                      </a>
                    </li>

                    <li>
                      <a href={`https://gtalentpro.com/events`} target="_blank">
                        Events
                      </a>
                    </li>
                  </>
                )}

                <div className="navbar-right">
                  <Dropdown overlay={userMenu}>
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                      style={{ textDecoration: "none" }}
                    >
                      <span className="firstLetter"> {userIcon}</span>
                    </a>
                  </Dropdown>
                </div>
              </>
            ) : (
              <></>
            )}
          </ul>
        </div>
      )}

      <div id="mobile">
        <div
          id="menu-icon"
          className={menuOpen ? "close-icon" : "bars-icon"}
          onClick={toggleMenu}
        >
          {menuOpen ? <IoMdClose /> : <FaBars />}
        </div>
      </div>

      {menuOpen && !loading && (
        <div id="mobile-menu">
          {userData ? (
            <ul>
              <li>
                <Link to="/dashboard" onClick={handleMenuItemClick}>
                  <HomeOutlined /> Dashboard
                </Link>
              </li>
              {userRole == "U" && (
                <>
                  <li>
                    <Link to="/profile" onClick={handleMenuItemClick}>
                      <UserOutlined /> Profile Update
                    </Link>
                  </li>

                  <li>
                    <Link to="/resume-templates" onClick={handleMenuItemClick}>
                      <FileTextOutlined /> Resume Templates
                    </Link>
                  </li>
                  <hr style={{ color: "#fff" }} />
                  <li>
                    <Link href="https://gtalentpro.com/jobs" translate="_blank">
                      <FileSearchOutlined /> Jobs
                    </Link>
                  </li>

                  <li>
                    <Link to="/jobs-applied" onClick={handleMenuItemClick}>
                      <UserOutlined /> Applied Jobs
                    </Link>
                  </li>
                  <li>
                    <Link to="/referrals" onClick={handleMenuItemClick}>
                      <QuestionCircleOutlined /> Referrals
                    </Link>
                  </li>
                  <hr style={{ color: "#fff" }} />
                  <li>
                    <Link to="/payment-history" onClick={handleMenuItemClick}>
                    <DiffOutlined /> Payment History
                    </Link>
                  </li>
                  <li>
                    <Link to="/pricing" onClick={handleMenuItemClick}>
                    <DollarOutlined /> Pricing
                    </Link>
                  </li>
                  <hr style={{ color: "#fff" }} />
                  <li>
                    <a
                      href="https://gtalentpro.com/companies"
                      translate="_blank"
                    >
                      <AuditOutlined /> Companies
                    </a>
                  </li>

                  <li>
                    <a href="https://gtalentpro.com/events" translate="_blank">
                      <CarryOutOutlined /> Events
                    </a>
                  </li>
                  <hr style={{ color: "#fff" }} />
                  <li>
                    <Link to="/support" onClick={handleMenuItemClick}>
                      <CheckCircleOutlined /> Support
                    </Link>
                  </li>
                </>
              )}

              {(userRole == "MP" || userRole =="A") && (
                <>
                  <hr style={{ color: "#fff" }} />
                  <li>
                    <Link to="/talent-list" onClick={handleMenuItemClick}>
                      <FileSearchOutlined /> Resume List
                    </Link>
                  </li>

                  <li>
                    <Link to="/new-talent" onClick={handleMenuItemClick}>
                      <AuditOutlined /> New Resume
                    </Link>
                  </li>
                  <hr style={{ color: "#fff" }} />
                  <li>
                    <Link to="/merchant-payment-history" onClick={handleMenuItemClick}>
                    <DiffOutlined /> Payment History
                    </Link>
                  </li>
                  <li>
                    <Link to="/merchant-pricing-card" onClick={handleMenuItemClick}>
                    <DollarOutlined /> Pricing
                    </Link>
                  </li>
                  <hr style={{ color: "#fff" }} />
                </>
              )}
 
              <li>
              <Link to="/settings" onClick={handleMenuItemClick}>
                  <SettingOutlined /> Settings
                </Link>
              </li>

              <li>
                <Link to="#" onClick={handleLogout}>
                  <LogoutOutlined /> Logout
                </Link>
              </li>
              <li>
                <Link to="/" onClick={handleMenuItemClick}>
                  <HomeOutlined /> Home
                </Link>
              </li>
            </ul>
          ) : (
            ""
          )}
        </div>
      )}
    </nav>
  );
}

export default Navbar;
