import { BASE_URL } from "../helper";
import api from "../api";

import Interceptor from "./interceptor";
const request = new Interceptor();

export const resumeTemplates = async () => {
  try {
    const response = await api.get(`${BASE_URL}/templates`);
    return response.data; // Return the response data instead of calling success function
  } catch (err) {
    // You can handle errors here or let the component handle them
    throw err; // Throw the error to be caught in the component
  }
};
